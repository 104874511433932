import React from "react";
import Navbar from './Navbar';
import Banner from './Banner';
import Stores from './Stores';
import Features from './Features';
import Steps from './Steps';
import Footer from './Footer';
import '../css/main.css'

function Website() {
  return (
    <>
      <Navbar />
      <Banner />
      <Stores />
      <Features />
      <Steps />
      <Footer />
    </>
  );
}

export default Website;
