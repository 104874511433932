import React from "react";
import phone from "../images/shoe5.png";
import wave from "../images/wave.png";
import "../css/features.css";

function Features() {
  return (
    <>
      <div className="feature-wrapper">
        <div className="box-one">
          <div>
            <img src={wave} alt="" />
          </div>
          <div>
            <p> Best deals at your finger tips</p>
            <p>Compare deals & discounts and become a smart shopper</p>
          </div>
        </div>
        <div className="box-two">
          <div>
            <img src={phone} alt="" />
          </div>
          <div>
            <p>Shop more to save more</p>
            <p>
              Shop for everything from apparels to electronics & furnishing to
              grocer and many more
            </p>
          </div>
        </div>
      </div>
      <div className="feature-wrapper-mobile">
        <div className="box one">
          <div>
            <img src={wave} alt="" />
          </div>
          <div>
            <p>Best deals at your finger tips</p>
            <p>
            Compare deals & discounts and become a smart shopper
            </p>
          </div>
        </div>
        <div className="box two">
          <div>
            <img src={phone} alt="" />
          </div>
          <div>
            <p>Shop more to save more</p>
            <p>
            Shop for everything from apparels to electronics & furnishing to
            grocer and many more
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
