import React from 'react';
import hutti_logo from '../images/hutti-unbackground.png';
import '../css/navbar.css';
function Navbar() {
  return (
    <div className='navbar-wrapper'>
      <img src={hutti_logo} alt="" id='hutti-logo' />
    </div>
  )
}

export default Navbar
