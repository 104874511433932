import React from "react";
import buy from "../images/buy_new2.png";
import brands from "../images/brand_new2.png";
import cashback from "../images/cashback_new2.png";
import "../css/steps.css";

function Steps() {
  return (
    <>
    <div className="steps-wrapper">
      <div className="steps-page-heading">
        <p>How it works?</p>
      </div>
      <div className="steps-info">
        <div>
          <div>
            <p>Step 1</p>
          </div>
          <div>
            <p>Select your favorite brand on Hutti app</p>
          </div>
          <div className="img-box">
            <img src={brands} alt="" />
          </div>
        </div>
        <div>
          <div>
            <p>Step 2</p>
          </div>
          <div>
            <p>
              Shop and buy as you normally do
            </p>
          </div>
          <div className="img-box">
            <img src={buy} alt="" id='buy' />
          </div>
        </div>
        <div>
          <div>
            <p>Step 3</p>
          </div>
          <div>
            <p>
            Get Hutti cashback in your bank account
            </p>
          </div>
          <div className="img-box">
            <img src={cashback} alt="" id='cashback' />
          </div>
        </div>
      </div>
    </div>
    <div className="steps-wrapper-mobile">
    <div className="steps-page-heading">
        <p>How it works?</p>
      </div>
      <div className="steps-info">
        <div>
          <div>
            <p>Step 1</p>
          </div>
          <div>
            <p>Select your favorite brand on Hutti app</p>
          </div>
          <div>
            <img src={brands} alt="" />
          </div>
        </div>
        <div>
          <div>
            <p>Step 2</p>
          </div>
          <div>
            <p>
              Shop and buy as you normally do
            </p>
          </div>
          <div>
            <img src={buy} alt="" id='buy_small'/>
          </div>
        </div>
        <div>
          <div>
            <p>Step 3</p>
          </div>
          <div>
            <p>
            Get Hutti cashback in your bank account
            </p>
          </div>
          <div>
            {" "}
            <img src={cashback} alt="" id='cashback_small' />
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Steps;
