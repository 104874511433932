import React, { useState } from "react";
import { Link } from "react-router-dom";
import Video from "./Video";
import hutti_logo from "../images/hutti-unbackground.png";
import mail from "../images/mail.png";
import location from "../images/location_2.png";
import badge from "../images/googleplay_badge.png";
import instagram from "../images/instagram.png";
import telegram from "../images/telegram.png";
import facebook from "../images/facebook.png";
import linkedin from "../images/linkedin.png";
import close from '../images/close.png'
import "../css/footer.css";

function Footer() {
  const currentYear = new Date().getFullYear();

  const [showVideo, setShowVideo] = useState(false);

  const openVideoPopup = () => {
    setShowVideo(true);
    document.body.style.overflowY = "hidden";
  };

  const closeVideoPopup = () => {
    setShowVideo(false);
    document.body.style.overflowY = "scroll";
  };

  return (
    <>
      <div className="footer-wrapper">
        <div className="footer-container-one">
          <div className="footer-box one">
            <img src={hutti_logo} alt="" id="hutti_logo_footer" />
            <p className="footer-links f-subheading">
              Hutti gives you money back on purchases you make through it
            </p>
            <Link
              to="https://play.google.com/store/apps/details?id=com.shop.hutti&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste%26utm_campaign%3Dhutti"
              className="footer-links"
            >
              <img src={badge} alt="" id="badge" />
            </Link>
            <p id="follow-us">Follow Us</p>
            <div className="social-media">
              <Link to = "https://t.me/+znVE4Iudq5Q4ODc1">
                <img src={telegram} alt="" />
              </Link>
              <Link to ="https://www.instagram.com/hutti_app/">
                <img src={instagram} alt="" />
              </Link>
              <Link to = "https://www.facebook.com/profile.php?id=61567431245797">
                <img src={facebook} alt="" />
              </Link>
              <Link>
                <img src={linkedin} alt="" />
              </Link>
            </div>
          </div>
          <div className="footer-box">
            <p className="footer-section-title">ABOUT</p>
            {/* <Link to="/" className="footer-links">
              Getting Started
            </Link> */}
            <p onClick={openVideoPopup} className="footer-links">
              About Us
            </p>
            <Link to="/terms" className="footer-links">
              Terms & Conditions
            </Link>
            <Link to="/privacy" className="footer-links">
              Privacy Policy
            </Link>
            {/* <Link to="/" className="footer-links">
              Help
            </Link> */}
          </div>
          <div className="footer-box">
            <p className="footer-section-title">CONTACT</p>
            {/* <div className="contact-box">
              <img src={call} alt="" />
              <p className="footer-links">+9195999081034</p>
            </div> */}
            <div className="contact-box">
              <img src={mail} alt="" />
              <a
                href="mailto:support@the-hutti.com"
                id="mail"
                className="footer-links"
              >
                support@the-hutti.com
              </a>
            </div>
            <div className="contact-box">
              <img src={location} alt="" />
              <p className="footer-links address">
                Office No. 129-30 Westend Mall Janakpuri West New Delhi 110058
              </p>
            </div>
          </div>
        </div>
        <div className="footer-container-two">
          <ul>
            <p id="copyright">
              All Rights Reserved |{" "}
              <span>
                <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
              </span>{" "}
              <span id="companyname">@Hutti&nbsp;</span>
              <span>{currentYear}&nbsp;</span>
            </p>
          </ul>
        </div>
      </div>
      {showVideo && (
        <div className="video-popup">
          <div className="video-popup-content">
            <p onClick={closeVideoPopup} className="close-popup">
              <img src={close} alt="" />
            </p>
            <Video />
          </div>
        </div>
      )}
      <div className="footer-wrapper-mobile">
        <div className="footer-container-one">
          <div>
            <div className="footer-box">
              <p className="footer-section-title">ABOUT</p>
              <p onClick={openVideoPopup} className="footer-links">
                About Us
              </p>
              <Link to="/terms" className="footer-links">
                Terms & Conditions
              </Link>
              <Link to="/privacy" className="footer-links">
                Privacy Policy
              </Link>
            </div>
            <div className="footer-box">
              <p className="footer-section-title">CONTACT</p>
              {/* <div className="contact-box">
                <img src={call} alt="" />
                <p className="footer-links">+9195999081034</p>
              </div> */}
              <div className="contact-box">
                <img src={mail} alt="" />
                <a
                  href="mailto:support@the-hutti.com"
                  id="mail"
                  className="footer-links"
                >
                 support@the-hutti.com
                </a>
              </div>
              <div className="contact-box">
                <img src={location} alt="" />
                <p className="footer-links">
                  Office No. 129-30 Westend Mall Janakpuri West New Delhi 110058
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="footer-box one">
              <img src={hutti_logo} alt="" id="hutti_logo_footer" />
              <p className="footer-links f-subheading">
                Hutti gives you money back on purchases you make through it
              </p>
              <Link
                to="https://play.google.com/store/apps/details?id=com.shop.hutti&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste%26utm_campaign%3Dhutti"
                className="footer-links"
              >
                <img src={badge} alt="" id="badge" />
              </Link>
              <p id="follow-us">Follow Us</p>
              <div className="social-media">
                <Link to = "https://t.me/+znVE4Iudq5Q4ODc1">
                  <img src={telegram} alt="" />
                </Link>
                <Link to = "https://www.instagram.com/hutti_app/">
                  <img src={instagram} alt="" />
                </Link>
                <Link to = "https://www.facebook.com/profile.php?id=61567431245797">
                  <img src={facebook} alt="" />
                </Link>
                <Link>
                  <img src={linkedin} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-container-two">
          <ul>
            <p id="copyright">
              All Rights Reserved |{" "}
              <span>
                <i className="fa fa-copyright" aria-hidden="true"></i> Copyright
              </span>{" "}
              <span id="companyname">@Hutti&nbsp;</span>
              <span>{currentYear}&nbsp;</span>
            </p>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
