import React from "react";
import "../css/privacy.css";

function Privacy() {
  return (
    <div className="privacy_wrapper">
      <h2>Privacy Policy</h2>
      <br />
      <p>
        Promosavvy Solutions Private Limited, referred to as "We," "Our," "Hutti," or "Us"
        (including its legal heirs, representatives, administrators, permitted
        successors, and assigns), is the owner and operator of the the-hutti.com
        website and mobile application ("Website" or "Site"). <br /> <br />
        In order to provide the Services (as defined in clause 1 below), Hutti
        needs to collect and utilize certain information from the Users of the
        Website ("Users") who utilize the Services, involving the capture,
        storage, and transmission of such information. This Privacy Policy
        ("Privacy Policy" or "Policy") elucidates how We gather, employ,
        distribute, and safeguard the personal information of Users of the
        Services, collectively referred to as "You," "Your," "Yourself," or
        "User" or "Users" herein. We have formulated this Privacy Policy to
        reaffirm our unwavering dedication to safeguarding the privacy of Users'
        information who engage with our Services. Your usage of and access to
        the Services are governed by this Privacy Policy and our Terms and
        Conditions. Any capitalized term utilized herein, but not expressly
        defined, shall bear the meaning ascribed to it in our Terms and
        Conditions. <br /> <br /> The headings utilized herein are solely for
        the purpose of organizing the various provisions of the Privacy Policy.
        These headings are for reference purposes only and should not be
        construed to restrict or amplify the provisions of the clauses contained
        therein.
      </p>
      <br />
      <ol>
        <li className="list_heading">Definitions</li>
        <br />
        <p>
          In this Privacy Policy, unless contextually specified otherwise, the
          defined terms carry the following meanings, and their related
          expressions are interpreted accordingly:
        </p>
        <br />
        <ol type="a">
          <li>
            {" "}
            "Personal Information" holds the same significance as defined in
            Rule 2(1)(i) of the Information Technology (Reasonable Security
            Practices and Procedures and Sensitive Personal Data or Information)
            Rules, 2011. It pertains to any information concerning a natural
            person that can directly or indirectly, in conjunction with other
            available or likely-to-be-available information to a corporate
            entity, identify such individual.
          </li>
          <br />
          <li>
            The SPI Rules further elucidate "Sensitive Personal Data or
            Information" concerning a person, including but not limited to:
          </li>
          <ol type="i" className="roman_list">
            <li>Passwords;</li>
            <li>
              Financial details like bank accounts, credit/debit card
              information, or other payment instrument particulars;
            </li>
            <li>Physical, physiological, and mental health status;</li>
            <li>Sexual orientation;</li>
            <li>Medical records and history;</li>
            <li>Biometric data;</li>
            <li>
              Information obtained by a corporate entity under contractual
              obligations or otherwise;
            </li>
            <li>
              Visitor particulars provided during registration or thereafter;
            </li>
            <li>Call data records.</li>
          </ol>
          <br />
          <li>
            "You," "Your," "Yourself," and "User" pertain to both natural and
            legal persons, as well as legal entities/companies, who access
            and/or utilize the Services. This also includes
            individuals/entities/companies availing the services through details
            provided by another person.
          </li>
          <br />
          <li>
            "Third Parties" denote any website/application/web portal, company,
            or individual besides the User and Us.
          </li>
          <br />
          <li>
            "Services" encompass the Website (https://www.the-hutti.com/) and Mobile
            Application (Hutti), along with contextual information exchanged
            with/received from Users via various communication channels such as
            e-mail, SMS, WhatsApp, notifications, phone calls, website chat, and
            IVR. Our primary business involves providing web and app-enabled
            cashback services. Presently, we operate under the brand name Hutti,
            primarily driving sales to e-commerce platforms we are affiliated
            with.
          </li>
          <br />
          <li>
            "User Information" comprises Personal Information and Sensitive
            Personal Data or Information.
          </li>
          <br />
          <li>
            "Website" refers to https://the-hutti.com/, while "Application" or "App"
            denotes the Hutti mobile application available on Android Play Store
            or iOS App Store. Collectively, they are referred to as the
            "Platform."
          </li>
        </ol>
        <br />
        <li className="list_heading">Objective of this Privacy Policy</li>
        <br />
        <p>
          This Privacy Policy is formulated in accordance with, among other
          regulations:
        </p>
        <br />
        <ol type="a">
          <li>
            The nature of information gathered from Users, encompassing
            Sensitive Personal Data or Information;
          </li>
          <br />
          <li>
            The objectives, methods, and channels of utilizing such information;
            and
          </li>
          <br />
          <li>How and to whom We intend to divulge this information.</li>
        </ol>
        <br />
        <li className="list_heading">Introduction</li>
        <br />
        <ol type="a">
          <li>
            The User acknowledges that this Policy, along with the mentioned
            Terms and Conditions, constitutes a legally binding agreement
            between the User and Hutti. The User agrees to abide by the rules,
            guidelines, policies, terms, and conditions governing any service
            provided by Hutti, including the Services. These are considered
            incorporated into the Terms and Conditions.
          </li>
          <br />
          <li>
            This document is an electronic record under the Information
            Technology Act, 2000, and related regulations. It does not require
            physical or digital signatures. Its publication follows the
            provisions of the SPI Rules and Intermediaries Guidelines.
          </li>
          <br />
          <li>
            "Party" and "Parties" refer to the User and Hutti individually and
            collectively, as context dictates.
          </li>
          <br />

          <li>
            Section headings in this Policy are for organizing provisions and
            hold no legal or contractual significance.
          </li>
          <br />
          <li>
            Hutti reserves the right to amend or modify the Policy and Terms and
            Conditions without prior notice to the User, in compliance with
            applicable laws. Users are responsible for staying updated on
            changes. Continued use of the Services implies consent to any
            amendments/modifications. Users are granted a personal,
            non-transferable privilege to access and use the Services, subject
            to compliance with the Policy and Terms and Conditions.
          </li>
        </ol>
        <br />
        <li className="list_heading">Personal Data Gathering and Management</li>
        <br />
        <p>
          Protecting the privacy of our users is a top priority for us, and all
          our Services are designed to comply with the laws set by the
          Government of India.
        </p>
        <br />
        <p>
          In general, to provide you with the best service possible, we may ask
          for certain information when you use our Services. This may include
          personally identifiable information or data that could identify you.
        </p>
        <br />
        <p>
          The information we collect from you may include, but is not limited
          to:
        </p>
        <br />
        <ol type="a">
          <li>Contact information (such as email address and phone number);</li>
          <br />
          <li>Usernames and passwords;</li>
          <br />
          <li>
            Demographic details (such as name, gender, age, date of birth, and
            postal code);
          </li>
          <br />
          <li>
            Information about your use of the services and any transactions
            conducted through them;
          </li>
          <br />
          <li>
            Data about your interactions with Hutti, including on mobile
            devices, web browsers, and browsing patterns;
          </li>
          <br />
          <li>
            Bank account details, including bank name, account number, IFSC
            code, and other payment-related information;
          </li>
          <br />
          <li>
            Any other information you choose to provide voluntarily, such as
            through emails, calls, or letters, including work details, home/work
            addresses, family details, e-commerce transaction details,
            transaction screenshots, order IDs, alternative contact information,
            and various other details provided over time.
          </li>
          <br />
          <p>
            The information collected from you constitutes "Personal
            Information" or "Sensitive Personal Data Information" under the SPI
            Rules.
          </p>
        </ol>
        <br />
        <li className="list_heading">Privacy Guidelines</li>
        <br />
        <p>The User expressly agrees and acknowledges:</p>
        <br />
        <ol type="a">
          <li>
            Information available in the public domain or accessible under laws
            like the Right to Information Act, 2005, will not be considered as
            'Personal Information' or 'Sensitive Personal Data or Information'
            under this Policy.
          </li>
          <br />
          <p>
            Hutti may automatically track User information using their IP
            address and behavior on the Platform, with the User's express
            consent. This information may be used for internal research on User
            demographics, interests, and behavior, aiding Hutti in better
            understanding and serving User interests. Users understand that this
            may include their computer and web browser details, IP address, and
            mobile device information. The link between the User's IP address
            and personally identifiable information may be shared with or
            disclosed to third parties to facilitate the provision of Services.
            Users consent to such sharing, and Hutti may also share aggregated
            findings in anonymized form with third parties for market research
            and new feature development.
          </p>
          <br />
          <li>
            Hutti may collect any and all information related to the User,
            regardless of whether it was directly provided by the User or
            obtained from sources such as emails, letters, SMS, WhatsApp
            notifications, calls, or feedback from other users or third parties
            regarding the User’s activities on the Platform. This information
            may be compiled by Hutti into a file or folder designated for the
            User, and the User explicitly consents to this practice.
          </li>
          <br />
          <p>
            Furthermore, to keep Users informed about their activities on the
            Website, Hutti occasionally sends various types of communication,
            including transaction messages showing Cashback earnings, referral
            messages indicating referral earnings, payment confirmations,
            important administrative messages, and activity confirmation
            messages. These communications are exclusively sent to the User and
            not shared with any other party.
          </p>
          <br />
          <p>
            Hutti also sends newsletters, WhatsApp notifications, SMSs, app
            notifications, browser notifications, and other marketing campaigns
            to provide users with innovative ways to save. Users have the option
            to opt-out of receiving such marketing communication from Hutti at
            any time.
          </p>
          <br />
          <p>
            Hutti also sends newsletters, WhatsApp notifications, SMSs, app
            notifications, browser notifications, and other marketing campaigns
            to provide users with innovative ways to save. Users have the option
            to opt-out of receiving such marketing communication from Hutti at
            any time.
          </p>
          <br />
          <li>
            The User's contact information provided to Hutti may be utilized to
            send offers and promotions, irrespective of whether they are based
            on the User’s past interests. The User explicitly consents to
            receiving such communications. If the User wishes to opt out of
            promotional communications, they can do so by clicking on the
            ‘unsubscribe’ link provided at the end of the promotional
            communication or by emailing Hutti at customersupport@the-hutti.com.
          </li>
          <br />
          <li>
            Hutti may at times request the User to participate in optional
            online surveys. These surveys might necessitate the User to furnish
            contact and demographic details such as zip code, age, income
            bracket, gender, etc. The User understands that this data is
            utilized to enhance and tailor the Services, ensuring they cater to
            the preferences and needs of both new and existing Users.
          </li>
          <br />
          <li>
            Hutti reserves the right to maintain records of electronic
            communications and phone calls, both received and made, for various
            purposes including but not limited to service administration,
            customer support, research and development, and providing better
            assistance to Users.
          </li>
          <br />
          <p>
            Additionally, Hutti may periodically ask Users to write reviews for
            services they have utilized from the Platform. Users acknowledge
            that such reviews contribute to assisting potential Users in
            availing the Services. By expressly authorizing Hutti, Users permit
            the publication of any reviews they write on the Platform, along
            with their name and certain contact details, for the benefit and
            utilization of other Users.
          </p>
          <br />
          <li>
            Hutti is not obligated to store, upload, publish, or display any
            content, reviews, surveys, or feedback submitted by the User. The
            User expressly permits Hutti to remove any such content, review,
            survey, or feedback from the Platform, without providing any cause
            or notification to the User.
          </li>
          <br />
          <li>
            The collection and generation of 'Sensitive Personal Data or
            Information' adhere to the provisions of the Information Technology
            Act, 2000, and associated regulations. Users explicitly consent to
            this process by affirming agreement to this Policy and clicking on
            the "I agree with Terms and Policy" button during registration,
            thereby complying with relevant legal requirements
          </li>
          <br />
          <li>
            It is the User's responsibility to ensure the accuracy of the
            information provided to Hutti. Users can rectify, delete
            inaccuracies, or amend information by contacting Hutti via email at
            customersupport@the-hutti.com. Hutti will endeavor to implement requested
            changes in its databases promptly. If the User submits information
            that is untrue, inaccurate, out-of-date, or incomplete, or if Hutti
            has reasonable grounds to suspect the provided information's
            accuracy, Hutti reserves the right, at its discretion, to cease
            providing services to the User as per the Terms and Conditions.
            Certain circumstances may prevent the correction, deletion, or
            updating of Personal Data, including instances where the data
            pertains to opinion data retained solely for evaluative purposes or
            if the data is in documents related to a prosecution pending
            completion of all proceedings.
          </li>
          <br />
          <li>
            All information provided to us by a User, including Sensitive
            Personal Data or Information, is voluntary. Users have the right to
            withdraw their consent at any time, in accordance with the terms of
            this Privacy Policy and the applicable Terms and Conditions. It is
            important to note that withdrawal of consent will not be
            retroactive. If a User wishes to delete their account or request
            that Hutti cease using their information to provide services, they
            can contact Hutti at customersupport@the-hutti.com. We will not retain such
            information longer than necessary for lawful purposes or as required
            by applicable laws. After a certain period, user data may be
            anonymized and aggregated for analytical purposes. However, please
            be aware that withdrawing consent or cancelling an account may limit
            our ability to provide services or terminate any existing
            relationship we may have with the User.
          </li>
          <br />
          <li>
            If you prefer not to receive non-essential communications, such as
            promotional and marketing-related information about our services,
            please send an email to customersupport@the-hutti.com to opt-out.
          </li>
        </ol>
        <br />
        <li className="list_heading">Our utilization of your information</li>
        <br />
        <p>
          Any data you provide to Hutti, whether it's Personal Information or
          Sensitive Personal Data or Information, is entirely voluntary. With
          your consent, this information may be shared with Third Parties. Hutti
          may utilize specific types of data, classified as Personal Information
          or Sensitive Personal Data or Information as per the SPI Rules, for
          various purposes:
        </p>
        <br />
        <ol type="a">
          <li>
            Service Provision: This involves providing you with our services.
          </li>
          <br />
          <li>
            Feedback Collection: Gathering feedback on our products and
            services.
          </li>
          <br />
          <li>
            Product and Service Promotion: Introducing new products/services and
            marketing existing ones.
          </li>
          <br />
          <li>
            Software Usage Analysis: Examining software usage patterns to
            enhance product design and utility.
          </li>
          <br />
          <li>
            Alerts/Reminders/SMS Services: Providing alerts, reminders, and SMS
            services for offers and internal records.
          </li>
          <br />
          <li>
            Commercial Utilization: Employing data for research, statistical
            analysis, and business intelligence, usually in an aggregated or
            non-personally identifiable form.
          </li>
          <br />
          <li>
            Sale or Transfer: Selling or transferring research, statistical, or
            intelligence data, without personal identification, to third parties
            and affiliates.
          </li>
          <br />
          <li>
            Customer Support Debugging: Addressing issues related to customer
            support.
          </li>
          <br />
          <p>
            Additionally, we may use tracking information like IP addresses or
            Device IDs to identify you and collect general demographic data.
          </p>
          <br />
          <p>
            If Hutti is acquired by or merged with another company, any
            information disclosed by you and about you will be transferred to
            the acquiring or merging company. This new entity will have the
            right to continue using your Personal Information and/or other
            information provided by you. In such a scenario, we will notify you
            via email or by posting a notice on the website and/or application
            before your Personal Information is transferred and subjected to a
            different privacy policy.
          </p>
          <br />
          <p>
            Users explicitly agree and acknowledge that Hutti collects and
            stores their Personal Information and/or Sensitive Personal
            Information on a secure cloud-based platform. This information is
            provided by users on the platform or while using other services.
            Users understand that this information is used by Hutti to provide
            its services and enhance the platform experience for safety and
            convenience. However, no personally identifiable information will be
            shared with any third party without the explicit consent of the
            user, unless required by law.
          </p>
          <br />
          <p>
            Hutti may be required to disclose or transfer a user's Personal
            Information to third parties under certain circumstances outlined in
            this Privacy Policy and the applicable Terms and Conditions:
          </p>
          <br />
          <ol type="a">
            <li>To government institutions or authorities as necessary:</li>
            <br />
            <ol type="i" className="roman_list">
              <li>
                To comply with laws, rules, and regulations or orders from
                relevant judicial or quasi-judicial authorities.
              </li>
              <br />
              <li>To protect and defend Hutti's rights or property.</li>
              <br />
              <li>To address fraud and credit risk.</li>
              <br />
              <li>To enforce the Terms and Conditions applicable to users.</li>
              <br />
              <li>
                . When deemed necessary by Hutti to protect its rights or the
                rights of others.
              </li>
            </ol>
            <br />
            <li>
              . If required by law or government agencies for identity
              verification, prevention, detection, investigation, including
              cyber incidents, prosecution, and punishment of offenses.
            </li>
            <br />
            <p>
              Additionally, Hutti may engage third parties to serve
              advertisements on its behalf across the Internet and on its site.
              These third parties may gather information about user visits to
              Hutti's website and interactions with its products and services to
              target advertisements. This data collection is facilitated through
              pixel tags, an industry-standard technology. However, these third
              parties are not authorized to sell or share personally
              identifiable information collected in this process.
            </p>
            <br />
            <p>
              Various third-party vendors, such as Google, Facebook, advertising
              platforms, remarketing platforms like CleverTap, and customer
              query management platforms like Freshworks & Exotel, use cookies
              to deliver ads based on a user's previous visits to Hutti's
              website. Google's utilization of the DoubleClick cookie allows it
              and its partners to display ads to users based on their
              interactions with Hutti's site or other websites on the Internet.
              Users have the option to opt out of the DoubleClick cookie for
              interest-based advertising by adjusting their Ads Settings
            </p>
          </ol>
        </ol>
        <br />
        <li className="list_heading">Our utilization of your information</li>
        <br />
        <ol type="1">
          <br />
          <li>
            Your information is considered confidential and will not be shared
            with any third parties, except as outlined here or as legally
            required by the appropriate authorities, or when necessary to
            provide services through the platform.
          </li>
          <br />
          <li>
            Your Personal Information/Sensitive Personal Data is stored
            electronically on our equipment and the equipment of our employees,
            and may also be converted to physical form as needed.
          </li>
          <br />
          <li>
            Those who can access your Personal Information: User information is
            handled by our employees, authorized staff, marketing agencies, or
            agents, as necessary for the specific purposes for which the
            information was collected. Hutti may retain and provide such records
            to relevant stakeholders.
          </li>
          <br />
          <li>
            Security Practices: We consider data as a valuable asset that must
            be safeguarded against loss and unauthorized access. We employ
            various security measures to protect such data from unauthorized
            access both internally and externally. We adhere to industry
            standards to protect the User Information submitted to us, including
            managerial, technical, operational, and physical security measures.
            However, we are not liable for any data loss or theft resulting from
            unauthorized access to the User's electronic devices used to access
            our services.
          </li>
          <br />
          <li>
            Steps we expect you to take: Your involvement in keeping your User
            Information safe and secure is crucial. When creating an online
            account, ensure you select a password that would be difficult for
            others to guess and never share your password credentials with
            anyone else. Opt for a password that includes a combination of
            letters, numbers, or special characters to enhance the security of
            your account. It's your responsibility to keep this password
            confidential and to monitor any activity on your account. If you use
            a shared or public computer, refrain from saving your login ID/email
            address or password, and always log out of your account before
            leaving the computer. Additionally, utilize any privacy settings or
            controls provided on our platform.
          </li>
          <br />
          <li>
            . Unauthorized use of user accounts: We do not assume liability for
            any unauthorized use of your account and password. If you suspect
            unauthorized activity on your account, promptly notify us by sending
            an email to customersupport@the-hutti.com detailing the issue.
          </li>
          <br />
          <li>
            Despite the above, Hutti cannot guarantee the confidentiality,
            security, or distribution of your Personal Information by third
            parties beyond the scope of our agreement with them. Furthermore,
            Hutti is not accountable for any security breaches or actions of
            third parties beyond our reasonable control, including governmental
            acts, computer hacking, unauthorized access to computer data and
            storage devices, computer malfunctions, security breaches, and
            encryption failures.
          </li>
        </ol>
        <br />
        <li className="list_heading">Retention of Your Personal Information</li>
        <br />
        <p>
          Per relevant laws, we will utilize and retain your User Information
          for as long as necessary to fulfill the purposes for which it was
          collected (outlined in Section 4 above) or to adhere to applicable
          legal obligations.
        </p>
        <br />
        <li className="list_heading">User Rights</li>
        <br />
        <ol type="1">
          <li>Access to Personal Data:</li>
          <br />
          <p>
            You have the privilege to review, access, and request either
            physical or electronic copies of the information retained about you.
            Furthermore, you can seek details about the origin of your Personal
            Information/Sensitive Personal Information.
          </p>
          <br />
          <li>
            Additional Entitlements (e.g., modification, deletion of Personal
            Data):
          </li>
          <br />
          <p>As permitted by law, you can:</p>
          <br />
          <ol type="i" className="roman_list">
            <li>
              Ask for the removal, transfer, rectification, or alteration of
              your User Information.
            </li>
            <br />
            <li>
              Restrict the utilization and divulgence of your Personal Data.
            </li>
            <br />
            <li>
              Withdraw consent from any of our data processing operations.
              Nonetheless, we might need to retain certain User Information even
              after your deletion request to comply with our business, legal, or
              contractual obligations.
            </li>
          </ol>
          <br />
        </ol>
        <li className="list_heading">
          "Privacy Protection for Children and Minors"
        </li>
        <br />
        <p>
          We urge parents and guardians to oversee the online engagements of
          their young children and to contemplate utilizing parental control
          features provided by online services and software developers to foster
          a child-friendly online setting. These tools can help prevent minors
          from sharing their personal details, such as their name and address,
          online without parental consent. While our services are not designed
          for use by minors, we uphold the privacy rights of every individual
          who might inadvertently access the internet or our mobile application
          to utilize our services.
        </p>
        <br />
        <li className="list_heading">Privacy Policy Consent</li>
        <br />
        <p>
          You recognize that this Privacy Policy constitutes an integral
          component of the Terms and Conditions governing the Website and other
          associated Services. Your unconditional acceptance of this Privacy
          Policy as a User of the Platform and the Services indicates your
          agreement to its terms. Your interaction with the Website, utilization
          of the App, and engagement with the Services are all bound by the
          provisions outlined in this Privacy Policy and the Terms and
          Conditions.
        </p>
        <br />
        <li className="list_heading">Cookies</li>
        <br />
        <p>
          When you use our Services on the Platform, a persistent cookie is
          placed on your computer. These cookies allow us to track any purchases
          you make with our participating retailers, and in return, we provide
          Cashbacks or Rewards to you. If you do not have such persistent
          cookies enabled on your computer, you will not be able to earn any
          Cashbacks or Rewards on your online shopping via our Platform.
        </p>
        <br />
        <p>
          Disabling/enabling cookies: You have the ability to accept or decline
          cookies by modifying the settings in your browser. However, you may
          not be able to use all the interactive features of our Platform if
          cookies are disabled.
        </p>
        <br />
        <p>
          Please note: If you disable the cookies in your browser that are used
          to track your purchases via our Platform, you will not be able to earn
          Cashbacks or Rewards when you shop from our website.
        </p>
        <br />
        <p>
          There are several ways to manage cookies. If you use different
          computers at different locations, you will need to ensure that each
          browser is adjusted to suit your cookie preferences
        </p>
        <br />
        <p>
          You can easily delete any cookies that have been installed in the
          cookie folder of your browser. For example, if you are using Microsoft
          Windows Explorer:
        </p>
        <br />
        <ol type="a">
          <li>Open 'Windows Explorer'</li>
          <br />
          <li>Click on the 'Search' button on the toolbar</li>
          <br />
          <li>Type "cookie" into the search box for 'Folders and Files'</li>
          <br />
          <li>Select 'My Computer' in the 'Look In' box</li>
          <br />
          <li>Click 'Search Now'</li>
          <br />
          <li>Double click on the folders that are found</li>
          <br />
          <li>'Select' any cookie file</li>
          <br />
          <li>Hit the 'Delete' button on your keyboard</li>
          <br />
          <p>
            If you are using any other browser, then you should select "cookies"
            in the "Help" function for information on where to find your cookie
            folder.
          </p>
        </ol>
        <br />
        <li className="list_heading">CHANGES TO PRIVACY POLICY</li>
        <br />
        <p>
          Hutti reserves the right to update this Privacy Policy at any time,
          with or without advance notice. If there are significant changes in
          the way Hutti handles User Information or in the Privacy Policy
          itself, Hutti will notify Users by displaying a notice on the Website
          or sending an email. This allows Users to review the updated terms
          before continuing to use the Services. If a User objects to any
          changes and no longer wishes to use Our Services, they can deactivate
          their account by contacting customersupport@the-hutti.com. Unless stated
          otherwise, the current Privacy Policy applies to all information that
          Hutti has about Users and their accounts.
        </p>
        <br />
        <p>
          By continuing to use the Services after receiving notice of changes,
          Users automatically consent to the updated terms.
        </p>
        <br />

        <li className="list_heading">Privacy Related Queries</li>
        <br />
        <p>
          If you have any questions about this Privacy Policy or Hutti’s
          practices regarding the collection, use, and disclosure of
          information, you may contact the Data Protection Officer appointed by
          Hutti. We will make reasonable efforts to promptly respond to any
          requests, questions, or concerns you may have regarding our use of
          your Personal Information. If you have any grievances regarding our
          use of your personal information, you may communicate such grievances
          to the Data Protection Officer.
        </p>
      </ol>

      <br />
      <br />
      <p>Name: {""}Nikhil Walia, IT Manager, hutti</p>
       <p>Mail:{" "}<a href="mailto:support@the-hutti.com">support@the-hutti.com</a></p>
    </div>
  );
}

export default Privacy;
