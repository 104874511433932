import "../css/anti-spam-policy.css";

function AntiSpamPolicy() {
  return (
    <div className="antispampolicy_wrapper">
      <h1>Anti Spam Policy</h1>

      <p>
        At hutti, we are committed to ensuring that you only receive
        communications from us that you have explicitly opted to receive. We do
        not engage in sending unsolicited emails or other communications, except
        as outlined in our Terms & Conditions and Privacy Policy. Rest assured,
        we do not share, sell, or rent your details to any other companies or
        third parties.
      </p>
      <p>
        You have the autonomy to manage your subscription preferences, including
        opting in or out of our monthly newsletters, through your 'Profile
        Details' page.
      </p>
      <p>
        We hold our members to a high standard of conduct and expect them to
        refrain from spamming our partner merchants or the public. Should we
        receive complaints from our partner merchants regarding members engaging
        in spamming activities, we will promptly terminate the offending
        members' accounts.
      </p>
      <p>
        If we become aware of any member sending unsolicited emails or engaging
        in spamming behavior, we will thoroughly investigate the matter.
        Depending on the severity of the offense, we may suspend the member's
        account or ban them entirely from our network of websites. We take such
        matters seriously to maintain the integrity of our platform and ensure a
        positive experience for all users.
      </p>
    </div>
  );
}

export default AntiSpamPolicy;
