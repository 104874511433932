import React from "react";
import "../css/terms.css";

function TermsAndConditions() {
  return (
    <div className="terms_wrapper">
      <h1>Terms & Conditions - the-hutti.com</h1>
      <br />
      <ol type="1">
        <li>Overview:</li>
        <br />
        <p>
          We appreciate your visit to www.the-hutti.com or the Hutti mobile
          application (collectively referred to as "Hutti") – India’s Largest
          Cashback Site. The Cashback Service is operated by Promosavvy
          Solutions Private Limited (“We,” “Us,” “Hutti,” or “Our” where such
          expression, unless repugnant to the context thereof, includes its
          respective legal heirs, representatives, administrators, permitted
          successors, and assigns). By utilizing the various services available
          on the Hutti Platform (“Services”), you agree to abide by these terms,
          guidelines, and supplemental terms provided for the Services that you
          use (collectively, “Terms”), reflecting Hutti's commitment to
          enhancing every User’s experience on the platform. Please review these
          terms and conditions (“Terms and Conditions” or “Agreement”) carefully
          as they constitute the legal terms and conditions governing your use
          of the service provided by us through the Website and the App
          (“Platform,” “Website”).Remember to revisit this page periodically to
          review the most recent version of the Agreement.
        </p>
        <br />
        <li>Membership:</li>
        <br />
        <p>
          Our membership is attainable through the registration process,
          requiring you to provide certain requested information to Hutti. A
          user must carefully read and agree to the prevailing Terms and
          Conditions, which are mandatory to follow while availing Our services.
          Please note that Hutti members are prohibited from running any paid
          ads on Google, Facebook, or any other platform directing traffic to
          Hutti. Failure to comply may lead to immediate termination of the
          member's account.
        </p>
        <br />
        <p>Declaration:</p>
        <br />
        <p>
          Purchases made by You using the Hutti platform must be solely for bona
          fide personal purposes. You are not permitted to utilize the Hutti
          Platform to purchase items for commercial resale or any other business
          purposes or to gain financial benefits. We reserves the right to block
          or terminate a User's account on the Hutti Platform without prior
          notice if, based on its risk management criteria, it reasonably
          suspects unauthorized usage of the Hutti Platform.
        </p>
        <br />
        <li>Services Offered:</li>
        <br />
        <p>Cashback Service:</p>
        <br />
        <p>
          Our Cashback Service enables users who register on the Platform
          (“Members”) and create an account with us (“Account”) to earn cashback
          by making purchases from the retailers listed on Our Platform
          (“Retailers”). To qualify for cashback, the Retailer must confirm the
          Member’s purchase as tracked, genuine, and successful (constituting a
          “Qualifying Transaction”), and the resulting cashback must be received
          by us (becoming “Cashback” as a result). The earned Cashback can be
          transferred directly to the bank accounts only after reaching the
          minimum threshold set by Us. A transaction is deemed qualified when
          the user is redirected by Hutti to the Retailer’s platform/Website,
          makes a valid and subsisting purchase, and the product/service is duly
          delivered, with the refund/return period expired. In case Retailers
          fail to track a transaction using their affiliate tracking system,
          such sales might not be paid to Hutti, and therefore, any due or
          expected cashback from these sales will also not be paid to the
          Member.
        </p>
        <br />
        <p>Reward Program:</p>
        <br />
        <p>
          Our Reward program allows registered users to earn Rewards by making
          purchases through Us and then being redirected to selective retailers,
          with whom we have partnered to provide rewards instead of cashbacks.
          Earnings in the form of rewards cannot be transferred to the user's
          bank account but can only be redeemed via gift cards or wallet, after
          reaching the minimum threshold set by Us.
        </p>
        <br />
        <p>Unique Referral Fee:</p>
        <br />
        <p>
          The Hutti Service also provides a unique referral fee to its Members,
          where Existing Members (referrers) receive 10% (Ten percent) cashback
          for each new Member (referee) introduced by them in the manner
          provided on the Platform (“Qualifying Referral”) as long as they
          remain a Member themselves. Referees transacting at specific
          stores/retailers may not qualify the referrer for the 10% cashback.
          Please refer to Clause 5 below for details. Hutti complies with the
          stringent laws of the land, and Members are obligated to adhere to the
          lawful use of our website. We are committed to enhancing our referral
          program and retain the right to modify any referral program terms at
          any time without notice.
        </p>
        <br />
        <li>Becoming a Member:</li>
        <br />
        <p>
          If You are a natural person, (i) as a condition of Your use of the
          Platform, You must be 18 (eighteen) years of age or older; and (ii) by
          visiting the Platform or accepting these Terms and Conditions, You
          represent and warrant to Hutti that You are 18 (eighteen) years of age
          or older, and that You have the right, authority, and capacity to use
          the Platform and agree to and abide by these Terms and Conditions;
          provided, however, that if You are a parent or legal guardian of a
          person younger than 18 (eighteen) years of age, You are permitted to
          access and use the Platform for the purposes of procuring the
          Services, on behalf of such persons. You must register for the
          Cashback Service using accurate and current information about yourself
          – including your correct name, address, and any other requested
          details. If you are asked for, and provide, details of a bank account,
          UPI, or other payment mode details through which you wish to receive
          the payouts (your “Cashback Receipt Method”), you (a) must ensure that
          you are, and remain, fully entitled to use that Cashback Receipt
          Method, and (b) confirm that you wish to receive cashback through that
          Cashback Receipt Method. You should keep this information updated
          through your Account. We reserve the right to discontinue or
          temporarily put on hold any payment methods listed on Our site, be it
          UPI, Bank Transfer, or Wallet/Gift Card. The generation and collection
          of ‘Sensitive Personal Data or Information’ are in accordance with the
          Information Technology Act, 2000, as amended from time to time, and
          allied rules requiring the User’s express consent. By clicking on the
          “I agree with Terms and Policy” button at the time of registration,
          the User provides affirming assent to such information generation and
          collection as required under applicable laws. Note that your Cashback
          Receipt Method (such as Bank Transfer, Gift Cards/Wallets, UPI, or
          other payment modes as We may introduce in the near future) provides a
          minimum Cashback threshold limit that a User needs to reach or attain
          in order to withdraw & receive Cashback through the opted Cashback
          Receipt Method. Important: You must ensure that the email address and
          mobile number we hold for you are kept up-to-date and that you have
          full access to them – We will be sending you important updates via
          text messages & Emails, App Notifications, and in some cases via
          WhatsApp. If you change your email address or mobile number, then you
          must change the details we hold for you on your Account.
        </p>
        <br />
        <br />
        <li>Cashback/Reward and Referral Fees:</li>
        <br />
        <p>
          Upon a Member successfully completing a Qualifying Transaction, and
          once we receive the resulting Cashback/Reward for that Qualifying
          Transaction, we transfer that Cashback/Reward to the Member through
          their chosen Cashback Receipt Method. It's essential to understand
          that there are various circumstances in which a transaction with a
          Retailer may not qualify as a Qualifying Transaction, or
          Cashback/Rewards may not result from it. This is also applicable to a
          Qualifying Referral. Members must understand that all transactions
          made by them are not with us but with the individual Retailer. Our
          help pages provide further information on these circumstances.
        </p>
        <br />
        <p>
          Moreover, Retailers may occasionally adjust the commission paid, which
          may affect the Cashback/Reward offer shown on our Platform. By
          default, your applicable transactions will be credited based on the
          commission reported to us by the Retailer, which may be more or less
          than the advertised rate. We shall not be liable for any discrepancy
          between the expected cashback/reward and the actual Cashback/reward
          received by the Member.
        </p>
        <br />
        <p>
          Once we have traced a paying, Qualifying Transaction to your Account,
          we will credit your Account with a ‘pending payment’. After the
          Retailer confirms the transaction, the payment will be marked as
          ‘validated’, a process that may take 90 days or more from the date of
          purchase. However, if the goods are returned or the sale reversed or
          amended in any way, the cashback/reward payment will not be processed.
          Besides the Retailer, it is also mandatory for members to notify Hutti
          of any returns or exchanges for which they might have earned
          cashback/reward unduly.
        </p>
        <br />
        <p>
          The determination of whether a transaction qualifies as a Qualifying
          Transaction (including cashback through a Qualifying Referral) rests
          solely with the Retailer or Us, and Members agree to this. We shall
          not be held responsible if the Retailer fails to report a sale to us
          or does not qualify a transaction as a Qualifying Transaction.
          Furthermore, we shall not be responsible if, due to technical or other
          errors, we cannot trace a sale back to the Member and thus cannot
          qualify the transaction as a Qualifying Transaction or a Qualifying
          Referral. While we will endeavor to recover non-payments from the
          Retailer, the decision of ourselves, the Retailer, or Retailer’s
          tracking agent shall be final. Additionally, we reserve the right not
          to pursue missing commission claims, especially where no purchase has
          been made.
        </p>
        <br />
        <p>
          Furthermore, if the Retailer deems a purchase as not genuine for any
          reason, and we do not receive any Cashback/Rewards for the
          transaction, the Member will not receive any Cashback/Rewards. When a
          Member submits an inquiry on missing cashback/reward by raising a
          ticket, our system checks if the user had an exit click from our
          Platform for that store on the date mentioned. If no exit clicks can
          be tracked, the Member will be informed that they did not click via
          our website to earn cashback/reward before the sale. All exit clicks
          to stores are saved in the database for cross-reference on adding
          cashback/reward values. If a commission enquiry has been successfully
          paid by a Retailer, the Member may receive a lower amount than
          expected due to differences in amounts received by us. If a Retailer
          still hasn’t paid a manual commission claim after six months, we
          reserve the right to close the enquiry claim. Payment for Missing
          Cashback/Reward is only applicable when the Retailer pays Hutti. It's
          important to note that the User raising or intending to raise a ticket
          for an enquiry of missing cashback/reward must do so within 10 (Ten)
          days from the transaction date. Claims submitted after this deadline
          will not be entertained.
        </p>
        <br />
        <p>
          We reserve the right to reclaim or make balance adjustments
          accordingly where it has been established that any credit has been
          applied in error. This includes transactions where the credit is not
          genuinely due or payment for any credited transaction has not been
          received from a Retailer or its agencies, and/or where there is
          evidence of misuse or fraud. Hutti reserves the right to recover all
          unduly paid cashback/rewards to which the member is not entitled,
          through legal proceedings. When a Member requests a pay-out, they must
          ensure to select the correct withdrawal method (Bank Transfer, Gift
          Card/wallet, UPIs, etc.) with accurate credentials. If incorrect
          details are provided and we process the pay-out(s), we shall not be
          liable for any errors, and negligence shall be considered the User’s
          error.
        </p>

        <br />
        <p>
          There are various circumstances in which Cashback/Rewards will not be
          payable to the Member and will be forfeited, including, without
          limitation:
        </p>
        <br />
        <p>
          - Where the cashback/reward payment received by us is not attributed
          to a Qualifying Transaction or associated with an Account (such as
          when the Member is not logged-in to our Platform during the purchase)
        </p>
        <br />
        <p>
          - If the transaction to which the Cashback/reward relates is canceled
          after being entered into
        </p>
        <br />
        <p>
          - The Cashback/Reward is attributed to a Member or Account that has
          been suspended by us for any reason or associated with fraudulent
          activity or breach of this Agreement.
        </p>
        <br />
        <p>
          Our help pages offer further information about these circumstances.
        </p>
        <br />
        <br />
        <li>Account Management:</li>
        <br />
        <p>
          Members have the option to withdraw or redeem their validated
          cashback/rewards once they meet the minimum threshold in their
          Account. Should a Member choose to close their Account with us and the
          validated Cashback/rewards in their account fall below the minimum
          threshold, the entire amount will be forfeited. However, if the
          validated Cashback/Reward in their account equals or exceeds the
          minimum threshold, they may request payment through any of the
          Cashback/Reward Receipt Methods. We retain the discretion to forfeit
          any validated balance displayed on the Member's Account for reasons
          outlined elsewhere in this Agreement.
        </p>
        <br />
        <li>Validity of Rewards and Bonuses:</li>
        <br />
        <p>
          Members acknowledge that Hutti Earnings will expire if they fail to
          log in to their Hutti account (via website or application) for a
          continuous period of 2 years. To prevent earnings from expiring,
          Members must log in to their Hutti account via the website or
          application at least once every 2 years. Upon expiry, the amount shall
          be removed from the Order Details page and listed under “Expired
          Cashbacks” menu as a single entry, which will not be withdrawable by
          the Member.
        </p>
        <br />
        <li>Protection of Intellectual Property:</li>
        <br />
        <p>
          Members recognize that all copyright, trademarks, and other
          intellectual property rights associated with the Platform are either
          owned by or licensed to us. Copying material from our portal is a
          violation of applicable laws, and we will take stringent actions
          against any User/visitor found engaging in such activities. Therefore,
          no individual may copy, distribute, publicly display, or create
          derivative works from the Platform or any material found on it unless
          authorized to do so by Us.
        </p>
        <br />
        <li>Data Privacy:</li>
        <br />
        <p>
          Our Privacy Policy constitutes an integral part of this Agreement. By
          entering into this Agreement, you agree to the manner in which we
          handle your personal data under that policy. Given the global reach of
          the World Wide Web, please note that any content posted on the
          Platform may be accessible to internet users worldwide.
        </p>
        <br />
        <li>Our Role in Transactions:</li>
        <br />
        <p>
          We do not participate in transactions with Retailers and do not serve
          as the seller or supplier of any goods or services made available by
          them. Consequently, we are not bound by the legal obligations that
          apply to the sellers of those goods or services. Thus, we disclaim any
          control over or responsibility for the quality, safety, or legality of
          goods or services available from Retailers, or whether the Retailer
          can or will supply and pass good title to any goods or services.
        </p>
        <br />
        <li>Preventing Abuse:</li>
        <br />
        <p>
          We reserve the right to suspend or terminate a Member's access to our
          service if, in our reasonable judgment, the said Member or Account
          violates any provision of this Agreement. Members must not engage in
          transactions with Retailers or obtain Cashback/Rewards through
          deceitful or unfair means, such as providing personal information of
          others or participating in fraudulent activities. In such cases of
          misuse of our service by the Member, we reserve the right to forfeit
          any pending or validated payments from the Member's Account.
        </p>
        <br />
        <li>Handling Third-party Communications:</li>
        <br />
        <p>
          Should anyone contact Us regarding material or transactions associated
          with a Member or their Account, the Member agrees to provide all
          reasonable information and assistance required by Us to respond to
          such inquiries. Additionally, Members are obligated to respond
          promptly and accurately to any messages forwarded to them for a
          response.
        </p>
        <br />
        <p>
          If Members observe or encounter anything on Our Platform that appears
          to violate any of the above requirements, We encourage them to notify
          Us via Our contact form. Each Member acknowledges that we have the
          right, though not the obligation, to remove any material that appears
          – based on information received from third parties or other Members –
          to violate this Agreement.
        </p>
        <br />
        <li>Supplementary Offerings:</li>
        <br />
        <p>
          Occasionally, We or Our affiliates may introduce new or supplementary
          services through this Platform. Your utilization of such services may
          necessitate compliance with additional terms and conditions, which you
          must adhere to. Upon your acceptance of these terms as notified on the
          Platform in a suitable manner (as determined by us at our discretion)
          when you opt for these services, any failure on your part to comply
          with a significant provision of the terms governing such services
          shall constitute a breach of this Agreement.
        </p>
        <br />
        <li> Operation of our Cashback/Reward Provision:</li>
        <br />
        <p>
          We retain the right to withdraw, amend, or suspend elements of the
          Cashback/Reward Provision, or its entirety, where legal, security,
          technical, or commercial grounds necessitate such actions. We shall
          endeavor to provide You with 30 (Thirty) days' prior notice before
          implementing such measures, except when immediate action is imperative
          due to security concerns or technical issues that could otherwise
          detrimentally impact our service. There might be instances when the
          Cashback/Reward Provision becomes inaccessible due to technical
          challenges faced by Us or on the Internet; however, We will exert
          reasonable effort and skill to address these challenges within our
          control. Nevertheless, We shall promptly notify Users about service
          disruptions, maintenance activities, or server downtime on our Portal
          and strive to minimize such occurrences to ensure smooth service
          delivery. For security purposes or other reasons, we may request you
          to update or modify passwords or other access-related information;
          however, we will never solicit your password via email, telephone, or
          any other means aside from the Platform. It is your sole
          responsibility to safeguard the confidentiality of your password and
          any additional identifying details.
        </p>
        <br />
        <li>Disclaimer and Restriction of Liability:</li>
        <br />
        <p>Disclaimer:</p>
        <br />
        <p>
          Content and material from or via the Platform are provided "as-is,"
          "as available," with "all faults," and all warranties, express or
          implied, are disclaimed (including but not limited to the disclaimer
          of any implied warranties of merchantability, non-infringement,
          freedom from error, and fitness for a particular purpose). Information
          and services may contain bugs, errors, problems, or other limitations.
          We and Our affiliated parties bear no liability whatsoever for your
          utilization of any information or service, except as specified in
          sub-section 13(d). Specifically, but not limited to, We and Our
          affiliated parties are not responsible for any indirect, special,
          incidental, or consequential damages (including damages for loss of
          business, profits, savings, litigation, or similar), whether based on
          breach of contract, breach of warranty, tort (including negligence),
          product liability, or otherwise, even if advised of the possibility of
          such damages. The negation and limitation of damages set forth above
          are fundamental aspects of the agreement between Hutti and you. This
          Platform and the products, services, documents, content, and materials
          presented would not be provided without such limitations. No advice or
          information, whether oral or written, obtained by You from Us through
          the Platform or otherwise, shall create any warranty, representation,
          or guarantee not expressly stated in this Agreement. All
          responsibility or liability for any damages caused by viruses
          contained within the electronic file containing a form or document is
          disclaimed.
        </p>
        <br />
        <p>Liability:</p>
        <br />
        <ol type="a">
          <li>
            We warrant that the Cashback/Reward Provision will be furnished with
            reasonable care and skill with the intention of meeting our
            specifications for the Cashback/Reward Provision; however, we cannot
            and do not assure that the Cashback/Reward Provision will fulfill
            your requirements.
          </li>
          <br />
          <li>
            We shall be liable solely as provided in this Agreement but shall
            not have any other obligation, duty, or liability whatsoever in
            contract, tort (including negligence, breach of statutory duty, and
            any other tort), or otherwise.
          </li>
          <br />
          <li>
            Limitation of Liability: Subject always to sub-Clause d. below, we
            shall be liable for direct loss or damage only, whether in contract,
            tort (including negligence, breach of statutory duty, or other
            tort), or otherwise, and whether caused by our act or omission or
            that of our employees, agents, or subcontractors. The total
            liability of Hutti and the affiliated parties in connection with any
            claim arising out of or related to the Platform and/or the products,
            information, documents, and services provided herein or hereby shall
            not exceed Rs 500, and that amount shall be in lieu of all other
            remedies which you may have against us and any affiliated party to
            us.
          </li>
          <br />
          <li>
            We will not be liable to you or anyone else, whether in contract,
            tort (including negligence, breach of statutory duty, or other
            tort), or otherwise
          </li>
        </ol>
        <br />
        <ol type="i">
          <li>
            (A) for any loss of revenue, business, anticipated savings, or
            profits; (B) any errors in or omissions from the Platform or any
            services or products obtainable therefrom; (C) the unavailability or
            interruption of the Platform or any features thereof; (D) your use
            of the Platform; (E) the content and materials contained on the
            Platform; (F) or any delay or failure in performance beyond our
            control or any of our affiliated parties.
          </li>
          <br />
          <li>
            for any indirect, special, or consequential loss damage, costs, or
            other claims, howsoever caused or arising, whether through
            non-supply or late supply of the Cashback Service or other
            non-performance of this Agreement or otherwise.
          </li>
          <br />
          <li>
            Except as expressly stated elsewhere in this Agreement, all
            representations, warranties, conditions, and other terms, whether
            express or implied (by common law, statute, collaterally, or
            otherwise) are hereby excluded, except in the case of fraud, or
            where such exclusion is not permitted by Applicable Law.
          </li>
          <br />
          <li>
            For the avoidance of doubt, we will not have liability to you or any
            other person in respect of material contributed by Members,
            transactions (or non-transactions) with Retailers, or any activity
            or communication relating to such material or transactions.
          </li>
          <br />
          <p>
            The provisions of this Clause 14 shall survive the termination or
            expiry of this Agreement.
          </p>
        </ol>
        <br />
        <li>Content from Third Parties:</li>
        <br />
        <p>
          Third-party content and materials may appear on the Platform or be
          accessible via hyperlinks from the Platform. We accept no
          responsibility for, and assume no liability whatsoever for any errors,
          misstatements of law, defamation, omissions, falsehoods, obscenity,
          pornography, or profanity in the statements, opinions,
          representations, or any other form of content and materials appearing
          on the Platform or accessible via hyperlinks from the Platform.
        </p>
        <br />
        <li>Correspondence:</li>
        <br />
        <p>
          You expressly consent to receive communications from Hutti, its
          affiliates, partners, and third parties, periodically, concerning the
          Service provided, significant updates, feedback & surveys, marketing,
          or any other pertinent messages via SMS, emails, calls (automated/
          agent generated), WhatsApp notifications, mobile application, browser
          notifications, or any other medium. You agree and consent to receive
          communications relating to the above and explicitly waive any
          registration or preference made under the applicable TRAI regulations.
        </p>
        <br />
        <li>Indenification:</li>
        <br />
        <p>
          You agree to indemnify us against all liabilities, claims, and
          expenses arising out of or in connection with (a) any breach of this
          Agreement by You or through Your Account, or (b) any transaction with
          a Retailer.
        </p>
        <br />
        <li>Transferability:</li>
        <br />
        <p>
          We reserve the right to assign this Agreement, as well as to assign or
          subcontract any or all of our rights and obligations under this
          Agreement, without diminishing any guarantees provided to you under
          this Agreement. You may not assign or transfer this Agreement, nor
          subcontract any of your rights and obligations under it, without our
          written consent.
        </p>
        <br />
        <li>Entire Understanding:</li>
        <br />
        <p>
          This Agreement constitutes the complete agreement between you and us
          regarding the Cashback/Reward Provision; we deem it to be fair and
          reasonable. It supersedes all previous agreements and understandings
          with you concerning the Cashback/Reward Provision, except for any
          instances of fraud or fraudulent representation by either party.
        </p>
        <br />
        <li>Modifications to this Agreement:</li>
        <br />
        <p>
          We reserve the right to amend this Agreement from time to time and
          post the updated version on the Cashback/Reward Provision. Upon doing
          so, the new version of the Agreement will be posted on the
          Cashback/Reward Provision, and it will govern the Cashback/Reward
          Provision and your relationship with us:
        </p>
        <br />
        <ol type="a">
          <li>
            commencing no fewer than 3 (three) days after the posting date (or
            such later date as We indicate in the relevant posting), if any of
            the changes affect an operative provision of this Agreement that
            could adversely affect You; if You wish to reject the new version of
            the Agreement, You may notify Us on or before the effective date of
            the new version of the Agreement, after which You must cease using
            Our service or
          </li>
          <br />
          <li>
            immediately upon the posting date (or such later date as We indicate
            in the relevant posting), if the changes do not affect operative
            provisions or do not adversely affect You – such as changes to
            contact details or refinements of existing provisions included in
            this Agreement.
          </li>
        </ol>
        <br />
        <li>Severability:</li>
        <br />
        <p>
          If any term of this Agreement is deemed invalid or unenforceable, the
          remainder of this Agreement shall remain valid and enforceable. You
          and Hutti are independent contractors, and no agency, partnership,
          joint venture, or employer-employee relationship is intended or
          created by this Agreement. Our failure to act regarding a breach by
          You or others does not waive our right to act regarding subsequent or
          similar breaches.
        </p>
        <br />
        <li>Grievance Redressal:</li>
        <br />
        <p>
          In accordance with the Information Technology Act, 2000, and the rules
          thereunder, as well as the Consumer Protection (E-Commerce) Rules,
          2020, the details of Hutti's Grievance Officer for the purpose of this
          Agreement are as follows:
        </p>
      </ol>
      <br />
      <p>Name: Ashish Saini</p>
      <p>Designation: Manager of Customer Experience</p>
      <p>
        Address: 129/130, Westend Mall, Janak Puri, New Delhi-110058
      </p>
      <p>
        Email ID:{" "}
        <a href="mailto:support@the-hutti.com">
        support@the-hutti.com
        </a>
      </p>
      <br />
      <p>
        Should any aggrieved user have questions or wish to lodge a complaint
        regarding any violation of the provisions of these Terms of Use, they
        may submit a written complaint to the Grievance Officer, who shall
        address the complaint within 1 (One) month of receiving it. Note: We
        may, at our discretion, modify the above details from time to time.
        Furnishing false, misleading, or inaccurate information to the grievance
        officer may result in civil and/or criminal liability.
      </p>
    </div>
  );
}

export default TermsAndConditions;
