import { Route, Routes } from "react-router-dom";
import Website from './Website';
import Privacy from "./Privacy";
import TermsAndConditions from "./TermsAndConditions";
import AntiSpamPolicy from "./AntiSpamPolicy";

function RouterPath() {
  const element = (
    <Routes>
      <Route path="/" element={<Website />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/anti-spam-policy" element={<AntiSpamPolicy />} />
    </Routes>
  );
  return element;
}

export default RouterPath;
